.pl {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pl-text {
  width: 50vw;
}

.pl-desc {
  margin-top: 5px;
  margin-bottom: 20px;
}

.pl-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .pl {
    padding: 30px;
  }

  .pl-title {
    padding-bottom: 20px;
  }

  .pl-desc {
    display: none;
  }

  .pl-list {
    justify-content: center;
    align-content: space-between;
    gap: 10px;
  }
}
