.i {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../img/backgroundImage.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.i * {
  background-color: transparent;
}

.i-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.i-title-container {
  height: 50px;
  overflow: hidden;
}

.i-title-wrapper {
  height: 100%;
  animation: move 4s ease-in-out infinite alternate;
}

@keyframes move {
  /* 50% {
    transform: translateY(-50px);
  } */

  100% {
    transform: translateY(-50px);
  }
}

.i-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #ff7f11;
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-scroll-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 5vh;
}
