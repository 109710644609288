.p {
  width: 30%;
  height: 40vh;
  margin: 20px 20x;
  border: 2px solid #beb7a4;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.p-browser {
  height: 20px;
  background-color: #404040;
  display: flex;
  align-items: center;
  /* position: sticky; */
  /* z-index: 1; */
}

.p-circle-wrapper {
  display: flex;
  background-color: transparent;
}

.p-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
}

.p-circle-red {
  background-color: #ff605c;
}

.p-circle-orange {
  background-color: #ffbd44;
}

.p-circle-green {
  background-color: #00ca4e;
}

.p-img {
  height: 100%;
  width: 100%;
  margin: auto;
}

@media screen and (max-width: 480px) {
  .p {
    width: 50vw;
    height: 20vh;
  }
}
