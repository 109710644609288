.c {
  text-align: center;
  padding-top: 10px;
}

.c-subtitle {
  margin-top: 5px;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

input,
textarea {
  width: 50vw;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #ff7f11;
}

input:focus,
textarea:focus {
  outline: 2px solid #ff7f11;
  border-radius: 10px;
}

button {
  border: none;
  padding: 10px;
  background-color: #ff7f11;
  color: white;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 10px;
}

.submit-text {
  background-color: #cbe896;
  padding: 10px 20px;
  border-radius: 10px;
  width: 75vw;
  /* Animation for hiding div */
  /* -moz-animation: cssAnimation 0s ease-in 5s forwards; */
  /* Firefox */
  /* -webkit-animation: cssAnimation 0s ease-in 5s forwards; */
  /* Safari and Chrome */
  /* -o-animation: cssAnimation 0s ease-in 5s forwards; */
  /* Opera */
  /* animation: cssAnimation 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; */
}

/* Animation keyframes for hiding div */
/* @keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
  }
}
@-webkit-keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
    padding: 0;
  }
} */

@media screen and (max-width: 480px) {
  .c-title {
    padding-bottom: 20px;
  }

  .c-subtitle {
    display: none;
  }
}
